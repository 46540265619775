<template>
  <div>
    <div class="branch-container parametrizacao">
      <div class="history pr-5">
        <el-button round @click="popupHistoricoAlteracoes = true" v-if="lastLog"
          ><i class="el-icon-timer"></i> Histórico de alterações</el-button
        >
        <el-button class="cadastro-btn" round @click="goCreate()">
          Nova modalidade</el-button
        >
        <el-button
          @click="goConfig()"
          icon="el-icon-setting
                "
          circle
        ></el-button>
      </div>
      <vs-row class="main-card">
        <vs-col
          vs-w="4"
          class="card-item"
          v-for="(item, index) in modalityList"
          :key="index"
        >
          <div class="content">
            <vs-dropdown
              vs-custom-content
              vs-trigger-click
              class="btn-action-proposta more"
            >
              <vs-button color="dark" type="flat" title="Ações">
                <i class="fas fa-ellipsis-v" role="button"></i>
              </vs-button>
              <vs-dropdown-menu
                class="btn-action-proposta-options w-2/5 md:w-1/3 lg:w-1/4 xl:w-1/5"
              >
                <vs-dropdown-item @click="goEdit(item)">
                  <p class="text-base px-6 py-2">
                    <span>Editar</span>
                  </p>
                </vs-dropdown-item>
                <vs-dropdown-item
                  divider
                  @click="alterarStatusModalidade(item)"
                >
                  <p class="text-base px-6 py-2">
                    <span style="color: red" v-if="item.Ativo">Desativar</span>
                    <span style="color: green" v-else>Ativar</span>
                  </p>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <div :class="item.Ativo ? 'ativo' : 'inativo'">
              <span> {{ item.Ativo ? "Ativo" : "Inativo" }}</span>
            </div>
            <div class="title">{{ item.NomeModalidade }}</div>
            <div v-if="item.ISReajuste">
              <div class="tipo" v-if="item.IndiceId == 1">Índice - IPCA</div>
              <div class="tipo" v-if="item.IndiceId == 2">Índice - SELIC</div>
              <div class="tipo" v-if="item.IndiceId == 3">Índice - Outros</div>
            </div>
            <div v-else>
              <div style="color: white;">1</div>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <!-- Pop up Histórico de alterações -->
    <el-dialog
      :visible.sync="popupHistoricoAlteracoes"
      close-on-click-modal
      modal-append-to-body
      center
      class="history-change"
    >
      <div class="pop-up-content">
        <div class="pop-up-content-header">
          <img
            src="@/assets/images/icons/ccg-icons/history-icon-ccg.svg"
            alt="Ícone de excluir representante"
            width="24"
            height="24"
          />
          <h3>Histórico de alterações</h3>
        </div>

        <div class="pop-up-content-comparation">
          <div class="pop-up-content-comparation-header">
            <div class="pop-up-content-comparation-header-data">
              Dados
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-before">
              Antes
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-after">
              Depois
            </div>
          </div>
          <ul class="pop-up-content-comparation-items">
            <li
              class="pop-up-content-comparation-item"
              v-for="(alteracao, index) in logs"
              :key="index"
            >
              <div class="pop-up-content-comparation-item-data">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Data:
                  </p>
                  <p
                    class="pop-up-content-comparation-item-info"
                    v-if="alteracao.Data"
                  >
                    {{ $utils.dateToDDMMYYYY(alteracao.Data) }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Usuário:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.NomeUsuario }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Ação:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Acao }}
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider"></div>

              <div class="pop-up-content-comparation-item-before">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-info">
                    <el-button
                      v-if="alteracao.Antes.length > 0"
                      type="primary"
                      icon="el-icon-view"
                      circle
                      @click="showItens(alteracao.Antes)"
                      >Visualizar</el-button
                    >
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider-arrow">
                <i class="el-icon-right"></i>
              </div>

              <div class="pop-up-content-comparation-item-after">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    <el-button
                      v-if="alteracao.Depois.length > 0"
                      type="primary"
                      icon="el-icon-view"
                      circle
                      @click="showItens(alteracao.Depois)"
                      >Visualizar</el-button
                    >
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div slot="footer" class="dialog-footer pop-up-footer">
        <el-button type="primary" @click="popupHistoricoAlteracoes = false"
          >Fechar</el-button
        >
      </div>
    </el-dialog>
    <vs-popup
      title=""
      :active.sync="disclamerModal"
      :buttonCloseHidden="true"
      class="disclamer"
    >
      <div class="vx-row px-8">
        <div class="vx-col w-12/12">
          <h2 style="color: red">ATENÇÃO!!</h2>
          <br />
          <p class="mt-4">
            Para continuar o cadastro de <b> modalidades </b> na renovação, é
            necessário configurar os <b> perfis de acesso </b> que vão receber
            as mensagens de aviso. Assim como o título e a mensagem do e-mail de
            Aviso de Renovação Automática.
          </p>
          <p class="mt-4">
            <vs-button class="button-primary text-center" @click="goConfig()">
              Prosseguir
            </vs-button>
          </p>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import instance from "@/axios";
import { Base64 } from "js-base64";
import renovacaoController from "@/providers/renovacao-provider.js";
export default {
  components: {},
  data() {
    return {
      popupHistoricoAlteracoes: false,
      modalityList: [],
      indexItem: 0,
      tagSelected: "",
      modalTags: false,
      tags: [],
      logs: {},
      lastLog: false,
      dataParams: false,
      emailTemplate: [
        { Title: "", Body: "" },
        { Title: "", Body: "" }
      ],
      joditConfig: { language: "pt_br" },
      teste: false,
      bolean: true,
      ramos: [],
      modalidadeList: [],
      groupList: [],
      modalityGroupList: [],
      disclamerModal: false
    };
  },
  async mounted() {
    await this.verifyRules();
    await this.getData();
    await this.ObterLogAuditoria();
    await this.ObterTagsAutomaticas();
  },
  methods: {
    ...mapActions("seguradora-module", [
      "getAllBranch",
      "getModalitiesByBranch"
    ]),
    goConfig() {
      this.$router.push({
        name: "param-configurar-renovacao"
      });
    },
    updateTitle(index) {
      this.$forceUpdate();
    },
    showItens(text) {
      this.$alert(text, " ", {
        confirmButtonText: "OK"
      });
    },
    addTagObject() {
      this.emailTemplate[this.indexItem].Body += `${this.tagSelected}`;
      this.modalTags = false;
      this.tagSelected = "";
    },
    addTagAction(index) {
      this.modalTags = true;
      this.indexItem = index;
    },
    async alterarStatusModalidade(modalidade) {
      let dados = modalidade;
      modalidade.Ativo = !modalidade.Ativo;
      await this.$onpoint.loading(async () => {
        return await renovacaoController
          .AtualizarParametroRenovacao(dados)
          .then(response => {
            this.$notify({
              title: "Success",
              message: "Dados atualizados com sucesso!",
              type: "success"
            });
            this.$router.push({
              name: "param-list-renovacao"
            });
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    async ObterTagsAutomaticas() {
      await this.$onpoint.loading(async () => {
        return await renovacaoController
          .obterListaTagsAutomaticas()
          .then(response => {
            this.tags = response;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    async ObterLogAuditoria() {
      await this.$onpoint.loading(async () => {
        return await renovacaoController
          .ObterListaLogAuditoriaModalidade()
          .then(response => {
            this.logs = response;
            this.lastLog = response.slice(-1).pop();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    goCreate() {
      this.$router.push({
        name: "param-criar-modalidade-renovacao"
      });
    },
    goEdit(modalidade) {
      let token = JSON.stringify(modalidade);
      token = Base64.encode(token);
      this.$router.push({
        name: "param-editar-modalidade-renovacao",
        params: { token }
      });
    },
    async getData() {
      await this.$onpoint.loading(async () => {
        return await renovacaoController
          .ObterParametrosRenovacaoPorSeguradora()
          .then(response => {
            this.modalityList = response;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    async verifyRules() {
      let profiles = null;
      let emailTemplate = null;
      this.$vs.loading();
      try {
        profiles = await renovacaoController.buscarListaPerfis();
        emailTemplate = await renovacaoController.buscarTemplateEmail();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      } finally {
        this.$vs.loading.close();
        if (profiles.length > 0 && emailTemplate.Title && emailTemplate.Body) {
          this.disclamerModal = false;
        } else {
          this.disclamerModal = true;
        }
      }
    }
  },
  created() {
    this.$appInsights.trackEvent({
      name: "param-modalidade"
    });
  }
};
</script>

<style lang="scss" scoped>
.branch-container {
  position: relative;
  margin-bottom: 24px;
  margin-top: 25px;

  .title {
    padding: 30px 0px 15px 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #636363;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .modality-group-content:not(:last-of-type) {
    border-bottom: 1px solid #e4e1e1;
  }

  .modality-group-content:not(:first-of-type) {
    padding-top: 16px;
  }

  .modality-group-content {
    margin: 0 16px 16px;
    padding-bottom: 16px;

    .modality-group-title {
      justify-content: space-between;
      padding-bottom: 28px;
      padding-top: 0px;
    }

    .data-content {
      color: #50555a;
      font-size: 14px;
      font-weight: 700;
    }

    .data-title {
      color: #8e8e8e;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.card-gray {
  background: #f7f7f7;
  color: #636363;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #63636321;
  padding: 25px 15px;
  display: flex;

  .left,
  .right {
    width: 50%;
  }

  .left {
    text-align: left;
  }

  .right {
    display: flex;
    flex-direction: row-reverse;
  }
}

.history {
  position: absolute;
  top: -65px;
  right: 0;
}
.ativo {
  border-radius: 10px;
  background: #7adc9e;
  width: max-content;
  padding: 5px 10px;
  color: black;
  font-weight: 500;
}
.inativo {
  border-radius: 10px;
  background: #e91212;
  width: max-content;
  padding: 5px 10px;
  color: white;
  font-weight: 500;
}

.card-item .content {
  background: white;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  .more {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
}

.card-item .content:hover {
  box-shadow: 10px 10px 5px 0px rgba(var(--vs-primary), 0.45);
}
.btn-view-changes {
  height: fit-content;
  padding: 10px 24px 10px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;

  &:hover {
    background: rgba(var(--vs-primary), 0.8) !important;
  }
}

.el-button.is-disabled {
  background: rgba(var(--vs-primary), 0.8) !important;
  pointer-events: none;
}

.pop-up-content {
  &-header {
    width: 100%;
    text-align: center;
    color: rgba(var(--vs-primary), 1);
    padding-bottom: 16px;
    border-bottom: 1px solid #747474;

    & i {
      width: 20px;
      height: 20px;

      &::before {
        font-size: 20px;
      }
    }

    & h3 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  &-comparation {
    padding: 36px 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-header {
      width: 100%;
      display: flex;
      padding: 0 16px 0 0;

      &-before,
      &-after,
      &-divider,
      &-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-divider {
        margin: 0 18px;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
        height: 50px;
        border-radius: 8px 8px 0 0;
        font-size: 18px;
        font-weight: 700;
        color: rgba(13, 27, 51, 1);
      }

      &-data {
        background-color: rgba(236, 236, 236, 1);
      }

      &-before {
        background-color: rgba(236, 236, 236, 1);
      }

      &-after {
        background-color: rgba(174, 255, 192, 1);
      }
    }

    &-items {
      height: 450px;
      overflow: auto;
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 36px 0;
      border-bottom: 1px solid rgba(116, 116, 116, 1);

      &-before,
      &-after,
      &-divider,
      &-divider-arrow,
      &-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
      }

      &-divider {
        margin: 0 18px;
      }

      &-divider-arrow {
        & i {
          width: 36px;
          height: 36px;

          &::before {
            font-size: 36px;
            color: rgba(236, 236, 236, 1) !important;
          }
        }
      }

      &-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-title {
        width: 50%;
        text-align: end;
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        word-break: break-word;
      }

      &-info {
        width: 50%;
        margin-left: 8px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(13, 27, 51, 1);
        word-break: break-word;
      }
    }

    &-item:last-child {
      border-bottom: none;
    }
  }
}

.pop-up-footer {
  padding: 36px 36px;
  border-top: 1px solid rgba(116, 116, 116, 1);
}
</style>

<style lang="scss">
.vs-popup {
  width: auto !important;
}
.addtag {
  float: right;
  border-radius: 0px !important;
}
.cadastro-btn {
  background-color: rgba(var(--vs-primary)) !important;
  color: white !important;
}
.cadastro-btn:hover {
  color: white !important;
}
</style>
