var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "branch-container parametrizacao" },
        [
          _c(
            "div",
            { staticClass: "history pr-5" },
            [
              _vm.lastLog
                ? _c(
                    "el-button",
                    {
                      attrs: { round: "" },
                      on: {
                        click: function ($event) {
                          _vm.popupHistoricoAlteracoes = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-timer" }),
                      _vm._v(" Histórico de alterações"),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "cadastro-btn",
                  attrs: { round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.goCreate()
                    },
                  },
                },
                [_vm._v("\n        Nova modalidade")]
              ),
              _c("el-button", {
                attrs: { icon: "el-icon-setting\n              ", circle: "" },
                on: {
                  click: function ($event) {
                    return _vm.goConfig()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "vs-row",
            { staticClass: "main-card" },
            _vm._l(_vm.modalityList, function (item, index) {
              return _c(
                "vs-col",
                {
                  key: index,
                  staticClass: "card-item",
                  attrs: { "vs-w": "4" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "vs-dropdown",
                        {
                          staticClass: "btn-action-proposta more",
                          attrs: {
                            "vs-custom-content": "",
                            "vs-trigger-click": "",
                          },
                        },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                color: "dark",
                                type: "flat",
                                title: "Ações",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-ellipsis-v",
                                attrs: { role: "button" },
                              }),
                            ]
                          ),
                          _c(
                            "vs-dropdown-menu",
                            {
                              staticClass:
                                "btn-action-proposta-options w-2/5 md:w-1/3 lg:w-1/4 xl:w-1/5",
                            },
                            [
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goEdit(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "text-base px-6 py-2" },
                                    [_c("span", [_vm._v("Editar")])]
                                  ),
                                ]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  attrs: { divider: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.alterarStatusModalidade(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "text-base px-6 py-2" },
                                    [
                                      item.Ativo
                                        ? _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("Desativar")]
                                          )
                                        : _c(
                                            "span",
                                            { staticStyle: { color: "green" } },
                                            [_vm._v("Ativar")]
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { class: item.Ativo ? "ativo" : "inativo" }, [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(item.Ativo ? "Ativo" : "Inativo")
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.NomeModalidade)),
                      ]),
                      item.ISReajuste
                        ? _c("div", [
                            item.IndiceId == 1
                              ? _c("div", { staticClass: "tipo" }, [
                                  _vm._v("Índice - IPCA"),
                                ])
                              : _vm._e(),
                            item.IndiceId == 2
                              ? _c("div", { staticClass: "tipo" }, [
                                  _vm._v("Índice - SELIC"),
                                ])
                              : _vm._e(),
                            item.IndiceId == 3
                              ? _c("div", { staticClass: "tipo" }, [
                                  _vm._v("Índice - Outros"),
                                ])
                              : _vm._e(),
                          ])
                        : _c("div", [
                            _c("div", { staticStyle: { color: "white" } }, [
                              _vm._v("1"),
                            ]),
                          ]),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "history-change",
          attrs: {
            visible: _vm.popupHistoricoAlteracoes,
            "close-on-click-modal": "",
            "modal-append-to-body": "",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.popupHistoricoAlteracoes = $event
            },
          },
        },
        [
          _c("div", { staticClass: "pop-up-content" }, [
            _c("div", { staticClass: "pop-up-content-header" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/icons/ccg-icons/history-icon-ccg.svg"),
                  alt: "Ícone de excluir representante",
                  width: "24",
                  height: "24",
                },
              }),
              _c("h3", [_vm._v("Histórico de alterações")]),
            ]),
            _c("div", { staticClass: "pop-up-content-comparation" }, [
              _c("div", { staticClass: "pop-up-content-comparation-header" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-data" },
                  [_vm._v("\n            Dados\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider",
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-before" },
                  [_vm._v("\n            Antes\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider",
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-after" },
                  [_vm._v("\n            Depois\n          ")]
                ),
              ]),
              _c(
                "ul",
                { staticClass: "pop-up-content-comparation-items" },
                _vm._l(_vm.logs, function (alteracao, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "pop-up-content-comparation-item",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pop-up-content-comparation-item-data" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Data:\n                "
                                  ),
                                ]
                              ),
                              alteracao.Data
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pop-up-content-comparation-item-info",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$utils.dateToDDMMYYYY(
                                              alteracao.Data
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Usuário:\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(alteracao.NomeUsuario) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Ação:\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(alteracao.Acao) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", {
                        staticClass: "pop-up-content-comparation-item-divider",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-before",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info",
                                },
                                [
                                  alteracao.Antes.length > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-view",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showItens(
                                                alteracao.Antes
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Visualizar")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pop-up-content-comparation-item-divider-arrow",
                        },
                        [_c("i", { staticClass: "el-icon-right" })]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-after",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  alteracao.Depois.length > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-view",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showItens(
                                                alteracao.Depois
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Visualizar")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer pop-up-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.popupHistoricoAlteracoes = false
                    },
                  },
                },
                [_vm._v("Fechar")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "disclamer",
          attrs: {
            title: "",
            active: _vm.disclamerModal,
            buttonCloseHidden: true,
          },
          on: {
            "update:active": function ($event) {
              _vm.disclamerModal = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row px-8" }, [
            _c("div", { staticClass: "vx-col w-12/12" }, [
              _c("h2", { staticStyle: { color: "red" } }, [
                _vm._v("ATENÇÃO!!"),
              ]),
              _c("br"),
              _c("p", { staticClass: "mt-4" }, [
                _vm._v("\n          Para continuar o cadastro de "),
                _c("b", [_vm._v(" modalidades ")]),
                _vm._v(" na renovação, é\n          necessário configurar os "),
                _c("b", [_vm._v(" perfis de acesso ")]),
                _vm._v(
                  " que vão receber\n          as mensagens de aviso. Assim como o título e a mensagem do e-mail de\n          Aviso de Renovação Automática.\n        "
                ),
              ]),
              _c(
                "p",
                { staticClass: "mt-4" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "button-primary text-center",
                      on: {
                        click: function ($event) {
                          return _vm.goConfig()
                        },
                      },
                    },
                    [_vm._v("\n            Prosseguir\n          ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }